var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('form',{staticClass:"my-form",attrs:{"action":""}},[_c('div',{staticClass:"mb-3 searching"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"my-form__input",attrs:{"type":"text","placeholder":_vm.$t('clients.search')},domProps:{"value":(_vm.search)},on:{"input":[function($event){if($event.target.composing)return;_vm.search=$event.target.value},_vm.SearchInput]}}),_vm._m(0)])]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-borderless my-table my-table-third"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_c('i',{staticClass:"fas fa-sort"}),_vm._v(" "+_vm._s(_vm.$t("clients.number"))+" ")]),_c('th',[_vm._v(_vm._s(_vm.$t("clients.fio")))]),_c('th',[_vm._v(_vm._s(_vm.$t("clients.phone")))]),_c('th',[_vm._v(_vm._s(_vm.$t("clients.amount")))]),_c('th',[_vm._v(_vm._s(_vm.$t("clients.apartment")))]),_c('th',[_vm._v(_vm._s(_vm.$t("clients.date")))]),_c('th')])]),_c('tbody',[(_vm.getLoading)?_c('tr',[_vm._m(1)]):_vm._e(),(_vm.getClients.length === 0 && !_vm.getLoading)?_c('tr',[_c('td',{attrs:{"colspan":"7"}},[_c('center',[_vm._v(" "+_vm._s(_vm.$t("no_data"))+" ")])],1)]):_vm._e(),_vm._l((_vm.getClients),function(status,index){return _c('tr',{key:index,class:[
              _vm.client.status === 'booking' ? 'table-warning' : '',
              _vm.client.status === 'cancelled' ? 'table-danger' : '',
            ]},[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(status.contract_number)+" ")]),_c('td',[_vm._v(" "+_vm._s(status.first_name)+" "+_vm._s(status.last_name)+" "+_vm._s(status.second_name)+" ")]),_c('td',[_vm._v("+"+_vm._s(status.phone))]),_c('td',[(_vm.client.price_apartment)?_c('span',[_vm._v(" "+_vm._s(_vm._f("number")(status.price_apartment,"0,0.00", { thousandsSeparator: " ", decimalSeparator: ",", }))+" "+_vm._s(_vm.$t("ye"))+" ")]):_vm._e()]),_c('td',[(_vm.client.apartment_id)?_c('span',[_vm._v(" "+_vm._s(status.apartment.number)+" ")]):_vm._e()]),_c('td',[(_vm.client.status == 'cancelled')?_c('small',[_vm._v(_vm._s(_vm._f("getStatus")(status.status,"", "")))]):_c('small',[_vm._v(_vm._s(_vm._f("getStatus")(status.status, _vm.$moment(status.buyed_date).format("DD.MM.YYYY"), _vm.$moment(status.apartment.booking_date).format( "DD.MM.YYYY" ) )))])]),_c('td',[_c('div',{staticClass:"dropdown my-dropdown dropleft"},[_vm._m(2,true),_c('div',{staticClass:"dropdown-menu"},[(
                      (_vm.client.status === 'booking' &&
                        _vm.client.manager_id === _vm.getMe.user.id) ||
                      (_vm.getMe.role.id === 1 && _vm.client.status === 'booking')
                    )?_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-view-client",modifiers:{"modal-view-client":true}}],staticClass:"dropdown-item dropdown-item--inside",on:{"click":function($event){return _vm.ReserveInfo(_vm.client)}}},[_c('i',{staticClass:"far fa-eye"}),_vm._v(" "+_vm._s(_vm.$t("apartments.list.view_client"))+" ")]):_vm._e(),(_vm.client.status == 'booking')?_c('router-link',{class:'dropdown-item dropdown-item--inside',attrs:{"to":{
                      name: 'apartment-view',
                      params: { id: _vm.client.apartment.id },
                    }}},[_c('i',{staticClass:"far fa-ballot-check"}),_vm._v(" "+_vm._s(_vm.$t("apartments.list.confirm"))+" ")]):_vm._e(),(_vm.client.status === 'buy')?_c('a',{staticClass:"dropdown-item dropdown-item--inside",attrs:{"href":"product-item.html"}},[_c('i',{staticClass:"far fa-eye"}),_vm._v(" "+_vm._s(_vm.$t("apartments.list.more"))+" ")]):_vm._e(),(_vm.client.status === 'buy')?_c('a',{staticClass:"dropdown-item dropdown-item--inside",attrs:{"href":"product-item.html"}},[_c('i',{staticClass:"fas fa-download"}),_vm._v(" "+_vm._s(_vm.$t("apartments.list.download_contract"))+" ")]):_vm._e(),(
                      (_vm.client.status === 'buy' &&
                        _vm.getPermission.clients.cancel_contract) ||
                      (_vm.client.status === 'buy' && _vm.getMe.role.id === 1)
                    )?_c('b-button',{staticClass:"dropdown-item dropdown-item--inside",on:{"click":function($event){return _vm.CancelContract(_vm.client.id)}}},[_c('i',{staticClass:"fas fa-eraser"}),_vm._v(" "+_vm._s(_vm.$t("apartments.list.cancel_contract"))+" ")]):_vm._e(),(_vm.getPermission.clients.delete)?_c('b-button',{staticClass:"dropdown-item dropdown-item--inside",on:{"click":function($event){return _vm.DeleteClient(_vm.client.id)}}},[_c('i',{staticClass:"far fa-trash"}),_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")]):_vm._e()],1)])])])})],2)]),_c('paginate',{attrs:{"pageCount":_vm.getClientsPaginate.pageCount,"clickHandler":_vm.PageCallBack,"prevText":`<i class='fa fa-chevron-left'></i>`,"nextText":`<i class='fa fa-chevron-right'></i>`,"container-class":'pagination',"page-class":'page-item',"page-link-class":'page-link',"next-class":'page-item',"prev-class":'page-item',"prev-link-class":'page-link',"next-link-class":'page-link'}})],1)]),(_vm.info_reserve)?_c('view-client',{attrs:{"apartment-data":_vm.apartment_preview,"client-id":_vm.client_id},on:{"CancelReserve":_vm.CloseReserveInfo}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',[_c('i',{staticClass:"far fa-search"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":"7"}},[_c('div',{staticClass:"d-flex justify-content-center w-100"},[_c('div',{staticClass:"lds-ellipsis"},[_c('div'),_c('div'),_c('div'),_c('div')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"dropdown-toggle",attrs:{"type":"button","data-toggle":"dropdown"}},[_c('i',{staticClass:"far fa-ellipsis-h"})])
}]

export { render, staticRenderFns }