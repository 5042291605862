<script>
export default {
  name: "BaseQuestionsIcon",
  props: {
    fill: {
      type: String,
      default: "#9CA3AF",
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
};
</script>
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M22 11.9998C22 17.5238 17.523 21.9998 12 21.9998C6.477 21.9998 2 17.5238 2 11.9998C2 6.47779 6.477 1.99979 12 1.99979C17.523 1.99979 22 6.47779 22 11.9998"
      :fill="fill"
    />
    <path
      d="M13.2033 9.38599C13.2033 9.05632 13.0797 8.78846 12.8324 8.58242C12.5934 8.37637 12.2761 8.27335 11.8805 8.27335C11.0728 8.27335 10.5701 8.65247 10.3723 9.41071H9C9.08242 8.71016 9.38324 8.13324 9.90247 7.67995C10.4217 7.22665 11.0893 7 11.9052 7C12.7376 7 13.397 7.21841 13.8832 7.65522C14.3777 8.09203 14.625 8.64423 14.625 9.31181C14.625 9.55907 14.592 9.78984 14.5261 10.0041C14.4602 10.2184 14.3942 10.3874 14.3283 10.511C14.2706 10.6346 14.1552 10.7747 13.9821 10.9313C13.8173 11.0797 13.7019 11.1786 13.636 11.228C13.5701 11.2692 13.4341 11.3599 13.228 11.5C13.2033 11.5165 13.1497 11.5536 13.0673 11.6113C12.9849 11.669 12.9313 11.706 12.9066 11.7225C12.8819 11.739 12.8324 11.7761 12.7582 11.8338C12.6923 11.8832 12.647 11.9203 12.6223 11.9451C12.6058 11.9615 12.5728 11.9986 12.5234 12.0563C12.4739 12.1058 12.4409 12.147 12.4245 12.1799C12.408 12.2129 12.3832 12.2582 12.3503 12.3159C12.3173 12.3736 12.2926 12.4313 12.2761 12.489C12.2679 12.5385 12.2596 12.5962 12.2514 12.6621C12.2431 12.728 12.239 12.7981 12.239 12.8723V13.1195H10.9038V12.7486C10.9038 12.3942 10.9615 12.0893 11.0769 11.8338C11.2005 11.5701 11.3365 11.3723 11.4849 11.2404C11.6332 11.1003 11.864 10.9272 12.1772 10.7212C12.4162 10.5563 12.5893 10.4327 12.6964 10.3503C12.8118 10.2596 12.9231 10.1277 13.0302 9.95467C13.1456 9.78159 13.2033 9.59203 13.2033 9.38599ZM10.9533 14.4423C11.1346 14.2692 11.353 14.1827 11.6085 14.1827C11.864 14.1827 12.0783 14.2692 12.2514 14.4423C12.4327 14.6154 12.5234 14.8297 12.5234 15.0852C12.5234 15.3407 12.4327 15.5591 12.2514 15.7404C12.0783 15.9135 11.864 16 11.6085 16C11.353 16 11.1346 15.9135 10.9533 15.7404C10.7802 15.5591 10.6937 15.3407 10.6937 15.0852C10.6937 14.8297 10.7802 14.6154 10.9533 14.4423Z"
      :fill="fill"
    />
  </svg>
</template>
