<script>
export default {
  name: "BaseRightDoubleIcon",
  props: {
    fill: {
      type: String,
      default: "#9CA3AF",
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
};
</script>
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="fill"
      d="M6.93661 6.66658C7.34527 6.66658 7.75607 6.82798 8.06845 7.15079L15.5292 14.8229C15.8309 15.1346 15.9999 15.5569 15.9999 15.9991C15.9999 16.4391 15.8309 16.8614 15.5292 17.1731L8.06845 24.8496C7.44155 25.4952 6.42739 25.4952 5.80049 24.8452C5.17573 24.1952 5.17787 23.1449 5.80477 22.4993L12.123 15.9991L5.80477 9.49884C5.17787 8.85323 5.17573 7.80523 5.80049 7.15521C6.11287 6.82799 6.52581 6.66658 6.93661 6.66658Z"
    />
    <path
      :fill="fill"
      d="M17.6034 6.66658C18.012 6.66658 18.4228 6.82798 18.7352 7.15079L26.196 14.8229C26.4976 15.1346 26.6667 15.5569 26.6667 15.9991C26.6667 16.4391 26.4976 16.8614 26.196 17.1731L18.7352 24.8496C18.1083 25.4952 17.0941 25.4952 16.4672 24.8452C15.8425 24.1952 15.8446 23.1449 16.4715 22.4993L22.7897 15.9991L16.4715 9.49884C15.8446 8.85323 15.8425 7.80523 16.4672 7.15521C16.7796 6.82799 17.1926 6.66658 17.6034 6.66658Z"
    />
  </svg>
</template>
