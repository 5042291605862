<script>
export default {
  name: "BaseDeleteIcon",
  props: {
    fill: {
      type: String,
      default: "#9CA3AF",
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
};
</script>
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.6432 9.48844C18.6432 9.55644 18.1103 16.2972 17.8059 19.1341C17.6152 20.875 16.4929 21.931 14.8094 21.961C13.5159 21.99 12.2497 22 11.0038 22C9.68115 22 8.38766 21.99 7.13209 21.961C5.50501 21.922 4.38171 20.845 4.20082 19.1341C3.88766 16.2872 3.36442 9.55644 3.3547 9.48844C3.34497 9.28345 3.41111 9.08846 3.54532 8.93046C3.67758 8.78447 3.8682 8.69647 4.06855 8.69647H17.9391C18.1385 8.69647 18.3194 8.78447 18.4623 8.93046C18.5956 9.08846 18.6627 9.28345 18.6432 9.48844Z"
      :fill="fill"
    />
    <path
      d="M20 5.97686C20 5.56588 19.6761 5.24389 19.2871 5.24389H16.3714C15.7781 5.24389 15.2627 4.8219 15.1304 4.22692L14.967 3.49795C14.7385 2.61698 13.9498 2 13.0647 2H8.93624C8.0415 2 7.26054 2.61698 7.02323 3.54595L6.87054 4.22792C6.7373 4.8219 6.22185 5.24389 5.62957 5.24389H2.71385C2.32386 5.24389 2 5.56588 2 5.97686V6.35685C2 6.75783 2.32386 7.08982 2.71385 7.08982H19.2871C19.6761 7.08982 20 6.75783 20 6.35685V5.97686Z"
      :fill="fill"
    />
  </svg>
</template>
