var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"creation-modal",attrs:{"id":"modal-create","title":_vm.modalProperties.title,"hide-footer":""},on:{"show":_vm.resetModal}},[_c('ValidationObserver',{ref:"validation-observer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('x-form-select',{staticClass:"mb-3",attrs:{"id":"select-managers","label":true,"options":_vm.typeOptions,"placeholder":_vm.$t('companies.type')},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('div',[_vm._v(_vm._s(_vm.$t("companies.type")))])]},proxy:true}],null,true),model:{value:(_vm.company.type_id),callback:function ($$v) {_vm.$set(_vm.company, "type_id", $$v)},expression:"company.type_id"}}),_vm._l((_vm.providerSchema),function({
          label,
          labelFor,
          bind,
          mask,
          rules,
          type,
          placeholder,
        }){return _c('ValidationProvider',{key:label + labelFor,attrs:{"name":label,"rules":rules},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-form-group',{staticClass:"select_input"},[_c('base-input',{attrs:{"type":type,"id":labelFor,"label":true,"placeholder":placeholder,"mask":mask},model:{value:(_vm.company[bind]),callback:function ($$v) {_vm.$set(_vm.company, bind, $$v)},expression:"company[bind]"}})],1),(errors[0])?_c('span',{staticClass:"error__provider"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})}),_c('div',{staticClass:"d-flex footer-btn justify-content-between pb-4"},[_c('b-button',{attrs:{"variant":"light"},on:{"click":_vm.resetModal}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('b-button',{staticClass:"ml-1 mr-0",attrs:{"type":"submit","variant":"success"}},[_c('span',{staticClass:"save__button"},[_vm._v(_vm._s(_vm.$t("save")))]),(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e()])],1)],2)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }