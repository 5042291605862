export const PROP_TYPE_ANY = undefined;
export const PROP_TYPE_ARRAY = Array;
export const PROP_TYPE_BOOLEAN = Boolean;
export const PROP_TYPE_DATE = Date;
export const PROP_TYPE_FUNCTION = Function;
export const PROP_TYPE_NUMBER = Number;
export const PROP_TYPE_OBJECT = Object;
export const PROP_TYPE_REG_EXP = RegExp;
export const PROP_TYPE_STRING = String;

export const PROP_TYPE_ARRAY_FUNCTION = [PROP_TYPE_ARRAY, PROP_TYPE_FUNCTION];
export const PROP_TYPE_ARRAY_OBJECT = [PROP_TYPE_ARRAY, PROP_TYPE_OBJECT];
export const PROP_TYPE_ARRAY_OBJECT_STRING = [
  PROP_TYPE_ARRAY,
  PROP_TYPE_OBJECT,
  PROP_TYPE_STRING,
];
export const PROP_TYPE_ARRAY_STRING = [PROP_TYPE_ARRAY, PROP_TYPE_STRING];
export const PROP_TYPE_BOOLEAN_NUMBER = [PROP_TYPE_BOOLEAN, PROP_TYPE_NUMBER];
export const PROP_TYPE_BOOLEAN_NUMBER_STRING = [
  PROP_TYPE_BOOLEAN,
  PROP_TYPE_NUMBER,
  PROP_TYPE_STRING,
];

export const PROP_TYPE_BOOLEAN_STRING = [PROP_TYPE_BOOLEAN, PROP_TYPE_STRING];
export const PROP_TYPE_DATE_STRING = [PROP_TYPE_DATE, PROP_TYPE_STRING];
export const PROP_TYPE_FUNCTION_STRING = [PROP_TYPE_FUNCTION, PROP_TYPE_STRING];
export const PROP_TYPE_NUMBER_STRING = [PROP_TYPE_NUMBER, PROP_TYPE_STRING];
export const PROP_TYPE_NUMBER_OBJECT_STRING = [
  PROP_TYPE_NUMBER,
  PROP_TYPE_OBJECT,
  PROP_TYPE_STRING,
];
export const PROP_TYPE_OBJECT_FUNCTION = [PROP_TYPE_OBJECT, PROP_TYPE_FUNCTION];
export const PROP_TYPE_OBJECT_STRING = [PROP_TYPE_OBJECT, PROP_TYPE_STRING];

export const PROP_TYPE_OBJECT_NUMBER_STRING_BOOLEAN = [
  PROP_TYPE_OBJECT,
  PROP_TYPE_NUMBER,
  PROP_TYPE_STRING,
  PROP_TYPE_BOOLEAN,
];

export const PROP_TYPE_ARRAY_OBJECT_NUMBER_STRING = [
  PROP_TYPE_ARRAY,
  PROP_TYPE_OBJECT,
  PROP_TYPE_NUMBER,
  PROP_TYPE_STRING,
];

export const PROP_TYPE_ARRAY_OBJECT_NUMBER_STRING_BOOLEAN = [
  PROP_TYPE_ARRAY,
  PROP_TYPE_OBJECT,
  PROP_TYPE_NUMBER,
  PROP_TYPE_STRING,
  PROP_TYPE_BOOLEAN,
];
