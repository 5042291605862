<script>
export default {
  name: "BaseChessPlan",
  props: {
    fill: {
      type: String,
      default: "#9CA3AF",
    },
    width: {
      type: Number,
      default: 21,
    },
    height: {
      type: Number,
      default: 20,
    },
  },
};
</script>

<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.9815 14.6297V5.37049C18.0037 5.37049 18.8334 4.5418 18.8334 3.51862C18.8334 2.49636 18.0037 1.66675 16.9815 1.66675C15.9583 1.66675 15.1296 2.49636 15.1296 3.51862H5.87042C5.87042 2.49636 5.04174 1.66675 4.01856 1.66675C2.99537 1.66675 2.16669 2.49636 2.16669 3.51862C2.16669 4.5418 2.99537 5.37049 4.01856 5.37049V14.6297C2.99537 14.6297 2.16669 15.4593 2.16669 16.4815C2.16669 17.5047 2.99537 18.3334 4.01856 18.3334C5.04174 18.3334 5.87042 17.5047 5.87042 16.4815H15.1296C15.1296 17.5047 15.9583 18.3334 16.9815 18.3334C18.0037 18.3334 18.8334 17.5047 18.8334 16.4815C18.8334 15.4593 18.0037 14.6297 16.9815 14.6297ZM4.94433 5.11305C5.22218 4.95191 5.4518 4.7223 5.61385 4.44445H7.72217L4.94433 7.22229V5.11305ZM4.94433 8.14815L8.64818 4.4443H10.5001L4.94442 9.99993L4.94433 8.14815ZM4.94433 10.9259L11.4259 4.44436H13.2777L4.94442 12.7777L4.94433 10.9259ZM5.32215 15.1777C5.21008 15.0648 5.08325 14.9675 4.94433 14.886V13.7036L14.2035 4.44442H15.3859C15.4674 4.58335 15.5647 4.71019 15.6776 4.82225L5.32215 15.1777ZM16.0554 14.886C15.7776 15.048 15.548 15.2776 15.3859 15.5555H14.2036L16.0554 13.7036V14.886ZM16.0554 12.7776L13.2776 15.5555H11.4257L16.0555 10.9257L16.0554 12.7776ZM16.0554 9.99991L10.4998 15.5555H8.64794L16.0554 8.14813L16.0554 9.99991ZM16.0554 7.22218L7.72214 15.5555H5.87028L16.0554 5.3704L16.0554 7.22218Z"
      :fill="fill"
    />
  </svg>
</template>

<style scoped></style>
