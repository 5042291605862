<script>
export default {
  name: "BaseContractsIcon",
  props: {
    fill: {
      type: String,
      default: "#9CA3AF",
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
};
</script>
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M18.8088 9.021C18.3573 9.021 17.7592 9.011 17.0146 9.011C15.1987 9.011 13.7055 7.508 13.7055 5.675V2.459C13.7055 2.206 13.5036 2 13.253 2H7.96363C5.49517 2 3.5 4.026 3.5 6.509V17.284C3.5 19.889 5.59022 22 8.16958 22H16.0463C18.5058 22 20.5 19.987 20.5 17.502V9.471C20.5 9.217 20.299 9.012 20.0475 9.013C19.6247 9.016 19.1177 9.021 18.8088 9.021Z"
      :fill="fill"
    />
    <path
      opacity="0.4"
      d="M16.0839 2.56731C15.7849 2.25631 15.2629 2.47031 15.2629 2.90131V5.53831C15.2629 6.64431 16.1739 7.55431 17.2799 7.55431C17.9769 7.56231 18.9449 7.56431 19.7669 7.56231C20.1879 7.56131 20.4019 7.05831 20.1099 6.75431C19.0549 5.65731 17.1659 3.69131 16.0839 2.56731Z"
      :fill="fill"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.97398 11.3876H12.359C12.77 11.3876 13.104 11.0546 13.104 10.6436C13.104 10.2326 12.77 9.89859 12.359 9.89859H8.97398C8.56298 9.89859 8.22998 10.2326 8.22998 10.6436C8.22998 11.0546 8.56298 11.3876 8.97398 11.3876ZM8.97408 16.3818H14.4181C14.8291 16.3818 15.1631 16.0488 15.1631 15.6378C15.1631 15.2268 14.8291 14.8928 14.4181 14.8928H8.97408C8.56308 14.8928 8.23008 15.2268 8.23008 15.6378C8.23008 16.0488 8.56308 16.3818 8.97408 16.3818Z"
      :fill="fill"
    />
  </svg>
</template>
