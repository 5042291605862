var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('base-modal',{ref:"plan-modal",attrs:{"design":"auto-height"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"modal-title"},[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("objects.create.plan.add_image"))+" ")]),_c('div',{staticClass:"go__back",on:{"click":_vm.cancel}},[_c('BaseCloseIcon',{attrs:{"width":56,"height":56}})],1)])]},proxy:true},{key:"main",fn:function(){return [_c('div',{staticClass:"uploader-container flex-column"},[_c('draggable',{staticClass:"row uploader-row ml-0",attrs:{"list":_vm.planData.images,"handle":".handle"}},[_vm._l((_vm.planData.images),function(img,index){return _c('div',{key:index,staticClass:"col-3"},[_c('div',{staticClass:"uploader"},[_c('div',{staticClass:"action-list"},[_c('div',{staticClass:"drag-plan handle"},[_c('base-drag-icon',{attrs:{"width":20,"height":20,"fill":"var(--white)"}})],1),_c('div',{staticClass:"delete-plan",on:{"click":function($event){return _vm.deleteImg(img, index)}}},[_c('base-delete-icon',{attrs:{"width":18,"height":18,"fill":"var(--white)"}})],1)]),_c('img',{attrs:{"src":_vm.createImage(img),"alt":"img"}})])])}),(_vm.planData.images && _vm.planData.images.length < 12)?_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"uploader"},[_c('image-uploader',{attrs:{"multiple":true},on:{"upload-image":_vm.setImage}})],1)]):_vm._e()],2)],1),_c('validation-observer',{ref:"form",staticClass:"create-layout",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},_vm._l((_vm.providerSchema),function({
            label,
            labelFor,
            bind,
            mask,
            rules,
            type,
          }){return _c('ValidationProvider',{key:label + labelFor,attrs:{"type":type,"name":label,"rules":rules},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{staticClass:"w-100",attrs:{"id":labelFor,"label":true,"error":!!errors.length,"placeholder":label,"mask":mask},model:{value:(_vm.planData[bind]),callback:function ($$v) {_vm.$set(_vm.planData, bind, $$v)},expression:"planData[bind]"}})]}}],null,true)})}),1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"footer-btn"},[_c('BaseButton',{attrs:{"fixed":true,"text":_vm.$t('cancel')},on:{"click":_vm.cancel}}),_c('BaseButton',{attrs:{"fixed":true,"design":"violet-gradient","text":_vm.$t('add')},on:{"click":_vm.add}})],1)]},proxy:true}])},[_c('BaseCameraIcon',{attrs:{"width":30,"height":27,"fill":"var(--gray-200)"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }