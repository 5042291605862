import { render, staticRenderFns } from "./TrashBasket.vue?vue&type=template&id=274fff9c&scoped=true&"
import script from "./TrashBasket.vue?vue&type=script&lang=js&"
export * from "./TrashBasket.vue?vue&type=script&lang=js&"
import style0 from "./TrashBasket.vue?vue&type=style&index=0&id=274fff9c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "274fff9c",
  null
  
)

export default component.exports