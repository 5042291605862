import { render, staticRenderFns } from "./DeleteHasApartment.vue?vue&type=template&id=8837e198&scoped=true&"
import script from "./DeleteHasApartment.vue?vue&type=script&lang=js&"
export * from "./DeleteHasApartment.vue?vue&type=script&lang=js&"
import style0 from "./DeleteHasApartment.vue?vue&type=style&index=0&id=8837e198&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8837e198",
  null
  
)

export default component.exports