var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"apartment-list-filter"},[_c('base-bread-crumb',{attrs:{"bread-crumbs":_vm.breadCrumbs,"active-content":_vm.activeContent}}),_c('div',{staticClass:"mt-4"},[_c('b-table',{ref:"apartment-list-table",staticClass:"custom-table",attrs:{"id":"my-table","sticky-header":"","borderless":"","show-empty":"","responsive":"","sort-icon-left":"","items":_vm.items,"fields":_vm.fields,"empty-text":_vm.$t('no_data')},scopedSlots:_vm._u([{key:"empty",fn:function(scope){return [_c('span',{staticClass:"d-flex justify-content-center align-items-center"},[_vm._v(_vm._s(scope.emptyText))])]}},{key:"cell(created_by)",fn:function(data){return [(data.item.created_by)?_c('span',[_vm._v(" "+_vm._s(data.item.created_by.first_name)+" "+_vm._s(data.item.created_by.last_name)+" ")]):_vm._e()]}},{key:"cell(expiry_at)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm.$moment(data.item.expiry_at).format("DD.MM.YYYY")))])]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"float-right"},[_c('div',{staticClass:"dropdown my-dropdown dropleft"},[_c('button',{staticClass:"dropdown-toggle",attrs:{"type":"button","data-toggle":"dropdown"}},[_c('i',{staticClass:"far fa-ellipsis-h"})]),_c('div',{staticClass:"dropdown-menu"},[_c('router-link',{class:'dropdown-item dropdown-item--inside',attrs:{"to":{
                  name: 'confirm-apartment',
                  params: {
                    id: data.item.uuid,
                    object: data.item.apartments[0].object.id,
                  },
                }}},[_c('i',{staticClass:"far fa-eye"}),_vm._v(" Продолжить оформление ")]),_c('b-link',{staticClass:"dropdown-item dropdown-item--inside",on:{"click":function($event){return _vm.cancelContract(data.item.uuid)}}},[_c('i',{staticClass:"far fa-trash text-danger"}),_vm._v(" Отменить оформление ")])],1)])])]}}]),model:{value:(_vm.sortDesc),callback:function ($$v) {_vm.sortDesc=$$v},expression:"sortDesc"}}),_c('b-overlay',{staticStyle:{"z-index":"2222"},attrs:{"show":_vm.getLoading,"no-wrap":"","opacity":"0.5"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-center w-100"},[_c('div',{staticClass:"lds-ellipsis"},[_c('div'),_c('div'),_c('div'),_c('div')])])]},proxy:true}])})],1),_c('div')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }