<script>
export default {
  name: "BaseLeftDoubleIcon",
  props: {
    fill: {
      type: String,
      default: "#9CA3AF",
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
};
</script>
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3966 25.3333C13.988 25.3333 13.5772 25.1719 13.2648 24.8491L5.80404 17.177C5.50236 16.8653 5.33333 16.443 5.33333 16.0008C5.33333 15.5608 5.50236 15.1385 5.80404 14.8268L13.2648 7.15028C13.8917 6.50467 14.9059 6.50467 15.5328 7.1547C16.1575 7.80472 16.1554 8.85493 15.5285 9.50054L9.21027 16.0008L15.5285 22.501C16.1554 23.1466 16.1575 24.1946 15.5328 24.8447C15.2204 25.1719 14.8074 25.3333 14.3966 25.3333Z"
      :fill="fill"
    />
    <path
      d="M25.0633 25.3333C24.6547 25.3333 24.2439 25.1719 23.9315 24.8491L16.4707 17.177C16.169 16.8653 16 16.443 16 16.0008C16 15.5608 16.169 15.1385 16.4707 14.8268L23.9315 7.15028C24.5584 6.50467 25.5726 6.50467 26.1995 7.1547C26.8242 7.80472 26.8221 8.85493 26.1952 9.50054L19.877 16.0008L26.1952 22.501C26.8221 23.1466 26.8242 24.1946 26.1995 24.8447C25.8871 25.1719 25.4741 25.3333 25.0633 25.3333Z"
      :fill="fill"
    />
  </svg>
</template>
