var render = function render(){var _vm=this,_c=_vm._self._c;return _c('x-modal-center',{attrs:{"bilingual":true,"apply-button-text":"save","cancel-button-text":"cancel","footer-class":"d-flex justify-content-between x-gap-1","apply-button-class":"w-100","cancel-button-class":"w-100","apply-button-loading":_vm.applyButtonLoading,"modal-container-style":{
    'max-width': '720px',
    'max-height': '420px',
    width: '75%',
    height: '100%',
    overflowY: 'scroll',
  }},on:{"close":_vm.closeCreatingModal,"cancel":_vm.closeCreatingModal,"apply":_vm.submitClientType},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',{staticClass:"x-font-size-36px font-craftworksans color-gray-600"},[_vm._v(" "+_vm._s(_vm.$t("holders.select"))+" ")])]},proxy:true},{key:"body",fn:function(){return [_c('validation-observer',{ref:"creating-type-observer",staticClass:"client-type-creating-body"},[_c('validation-provider',{ref:"vProvider01",staticClass:"name-provider",attrs:{"name":"vProvider01"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"holder-label mb-4",attrs:{"for":"select-managers"}},[_vm._v(" "+_vm._s(_vm.$t("holders.singular"))+" ")]),_c('x-form-select',{attrs:{"id":"select-managers","label":false,"options":_vm.optionList},model:{value:(_vm.clientForm.holder_id),callback:function ($$v) {_vm.$set(_vm.clientForm, "holder_id", $$v)},expression:"clientForm.holder_id"}}),(errors[0])?_c('span',{staticClass:"error__provider"},[_vm._v(" "+_vm._s(errors[0].replace("vProvider01", _vm.$t("holders.singular")))+" ")]):_vm._e()]}}])})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }