var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-overlay',{staticClass:"overlay__alert",attrs:{"show":_vm.loading,"rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('b-icon',{attrs:{"icon":"stopwatch","font-scale":"3","animation":"cylon"}}),_c('p',{attrs:{"id":"cancel-label"}},[_vm._v("Пожалуйста подождите...")])],1)]},proxy:true}])},[_c('b-alert',{staticClass:"success__alert",attrs:{"dismissible":"","variant":_vm.responseAlert.variant,"show":_vm.responseAlert.dismissCountDown},on:{"dismissed":_vm.dismissedAlert,"dismiss-count-down":_vm.countDownChanged}},[_c('p',[_vm._v(_vm._s(_vm.responseAlert.message))]),_c('b-progress',{attrs:{"variant":_vm.responseAlert.variant,"max":_vm.responseAlert.dismissSecs,"value":_vm.responseAlert.dismissCountDown,"height":"4px"}})],1),_c('div',{attrs:{"aria-hidden":_vm.hiddenArea}},[_c('ValidationObserver',{ref:"validation-observer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{staticClass:"form__password",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitNewPassword)}}},[_vm._l((_vm.providerSchema),function({
                type,
                name,
                rules,
                extraClass,
                id,
                label,
                placeholder,
                bind,
                validationError,
              },index){return _c('ValidationProvider',{key:name + id,staticClass:"mt-3",class:extraClass,attrs:{"name":name,"rules":rules},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{attrs:{"for":id}},[_vm._v(_vm._s(label))]),_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [(bind === 'newPassword')?_c('b-input-group-text',{staticClass:"toggle__password__view",on:{"click":_vm.copyToClipboard}},[_c('span',[_c('img',{staticStyle:{"width":"24px","height":"24px"},attrs:{"src":require("@/assets/icons/clipboard.svg"),"alt":"no-preview-aye.svg"}})])]):_vm._e(),(bind === 'newPassword')?_c('b-input-group-text',{staticClass:"toggle__password__view",on:{"click":_vm.regeneratePassword}},[_c('span',[_c('img',{staticStyle:{"width":"24px","height":"24px"},attrs:{"src":require("@/assets/icons/refresh.svg"),"alt":"no-preview-aye.svg"}})])]):_vm._e(),_c('b-input-group-text',{staticClass:"toggle__password__view",on:{"click":function($event){return _vm.toggleInputType(index)}}},[(type === 'password')?_c('span',[_c('img',{attrs:{"src":require("@/assets/icons/no-preview-aye.svg"),"alt":"no-preview-aye.svg"}})]):_c('span',[_c('img',{attrs:{"src":require("@/assets/icons/preview-aye.svg"),"alt":"preview-aye.svg"}})])])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":type,"id":id,"placeholder":placeholder},model:{value:(_vm.form[bind]),callback:function ($$v) {_vm.$set(_vm.form, bind, $$v)},expression:"form[bind]"}})],1),(errors[0])?_c('span',{staticClass:"error__provider"},[_vm._v(_vm._s(_vm.$t("user.validation_password")))]):_vm._e(),(validationError.show)?_c('span',{staticClass:"error__provider"},[(
                  _vm.checkedPasswordError &&
                  !(typeof validationError.message === 'string')
                )?_vm._l((validationError.message),function(message,index){return _c('span',{key:index},[_vm._v(_vm._s(message)),_c('br')])}):[_vm._v(" "+_vm._s(validationError.message)+" ")]],2):_vm._e(),(_vm.form['newPassword'] && bind === 'newPassword')?_c('div',{staticClass:"po-password-strength-bar",class:_vm.score}):_vm._e()]}}],null,true)})}),_c('div',{staticClass:"buttons"},[_c('b-button',{staticClass:"submit__button",attrs:{"disabled":_vm.loading,"type":"submit","variant":"btn-primary"}},[_vm._v(" "+_vm._s(_vm.$t("refresh_password"))+" ")])],1)],2)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }