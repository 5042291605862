<script>
export default {
  name: "BaseStarIcon",
  props: {
    fill: {
      type: String,
      default: "#9CA3AF",
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
};
</script>
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9184 14.4413C17.6594 14.7055 17.5404 15.0875 17.5994 15.4623L18.4884 20.6409C18.5634 21.0798 18.3874 21.524 18.0384 21.7776C17.6964 22.0408 17.2414 22.0723 16.8684 21.8618L12.4394 19.4304C12.2854 19.3441 12.1144 19.2978 11.9394 19.2925H11.6684C11.5744 19.3073 11.4824 19.3388 11.3984 19.3873L6.96839 21.8303C6.74939 21.946 6.50139 21.9871 6.25839 21.946C5.66639 21.8281 5.27139 21.2345 5.36839 20.6082L6.25839 15.4296C6.31739 15.0518 6.19839 14.6676 5.93939 14.3992L2.32839 10.7152C2.02639 10.4068 1.92139 9.94368 2.05939 9.52581C2.19339 9.109 2.53539 8.80481 2.94839 8.73639L7.91839 7.9775C8.29639 7.93645 8.62839 7.69436 8.79839 7.33649L10.9884 2.61049C11.0404 2.50523 11.1074 2.40839 11.1884 2.32629L11.2784 2.25261C11.3254 2.19788 11.3794 2.15262 11.4394 2.11578L11.5484 2.07368L11.7184 2H12.1394C12.5154 2.04105 12.8464 2.27788 13.0194 2.63154L15.2384 7.33649C15.3984 7.68067 15.7094 7.9196 16.0684 7.9775L21.0384 8.73639C21.4584 8.79955 21.8094 9.10479 21.9484 9.52581C22.0794 9.94789 21.9664 10.411 21.6584 10.7152L17.9184 14.4413Z"
      :fill="fill"
    />
  </svg>
</template>
