<script>
export default {
  name: "BaseCameraIcon",
  props: {
    fill: {
      type: String,
      default: "#9CA3AF",
    },
    width: {
      type: Number,
      default: 20,
    },
    height: {
      type: Number,
      default: 20,
    },
  },
};
</script>
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 30 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.16 5.35461C23.22 5.45949 23.325 5.53441 23.46 5.53441C27.06 5.53441 30 8.47114 30 12.0671V20.9673C30 24.5633 27.06 27.5 23.46 27.5H6.54C2.925 27.5 0 24.5633 0 20.9673V12.0671C0 8.47114 2.925 5.53441 6.54 5.53441C6.66 5.53441 6.78 5.47447 6.825 5.35461L6.915 5.17481C6.96671 5.06597 7.01978 4.95418 7.07366 4.84069C7.4574 4.03242 7.88194 3.1382 8.145 2.61265C8.835 1.26415 10.005 0.514983 11.46 0.5H18.525C19.98 0.514983 21.165 1.26415 21.855 2.61265C22.0912 3.08461 22.4512 3.84481 22.7981 4.57754C22.8697 4.72878 22.9407 4.87885 23.01 5.02497L23.16 5.35461ZM22.065 11.1082C22.065 11.8574 22.665 12.4567 23.415 12.4567C24.165 12.4567 24.78 11.8574 24.78 11.1082C24.78 10.359 24.165 9.74473 23.415 9.74473C22.665 9.74473 22.065 10.359 22.065 11.1082ZM12.405 13.4306C13.11 12.7264 14.025 12.3518 15 12.3518C15.975 12.3518 16.89 12.7264 17.58 13.4156C18.27 14.1049 18.645 15.0189 18.645 15.9928C18.63 18.0006 17.01 19.6337 15 19.6337C14.025 19.6337 13.11 19.2592 12.42 18.5699C11.73 17.8807 11.355 16.9667 11.355 15.9928V15.9778C11.34 15.0339 11.715 14.1199 12.405 13.4306ZM19.155 20.1582C18.09 21.222 16.62 21.8812 15 21.8812C13.425 21.8812 11.955 21.2669 10.83 20.1582C9.72 19.0344 9.105 17.566 9.105 15.9928C9.09 14.4345 9.705 12.9661 10.815 11.8424C11.94 10.7186 13.425 10.1043 15 10.1043C16.575 10.1043 18.06 10.7186 19.17 11.8274C20.28 12.9512 20.895 14.4345 20.895 15.9928C20.88 17.626 20.22 19.0943 19.155 20.1582Z"
      :fill="fill"
    />
  </svg>
</template>
