export default {
  blocks_of_the_object: "{objectName} блоки",
  document_type: "Тип документа",
  checkout: {
    address_line: "Место жительства",
  },
  filter: {
    by_role: "Фильтровать по роли",
  },
  select_type: "Выберите тип",
  reports: {
    title: "Отчет",
    not_found: "Отчетов не найдено",
  },
  report: {
    title: "Отчет",
    list: "Список отчетов",
    status: "Статус",
    clients: "Клиенты",
    orders: "Заказы",
    filter_by: "Сортировать по",
    select_report_type: "Выберите тип отчета",
    statuses: {
      created: "создано",
      processing: "обработку",
      successful: "успешный",
      failed: "неуспешный",
      cancelled: "отменен",
    },
  },
  from_the_date_of: "С даты",
  to_the_date_of: "До даты",
  download_report: "Сформировать",
  checkout_permission_error_when_initial_set_to_zero:
    "Невозможно внести первоначальный взнос 0 сум",
  checkout_warning_when_initial_set_to_zero:
    "Вы сделали первоначальный взнос 0 сумов",
  deleted_date: "Дата удаления",
  contracts_not_found: {
    title: "Договоры не были найдены",
    description: "Попробуйте ввести другие данные для поиска",
  },
  restore_contract: "Восстановить договор",
  contract_archive_title: "Архивирование договора",
  in_the_archive: "В архиве",
  move_to_archive: "Архиворовать",
  remove_from_archive: "Разархивировать",
  deselect: "Отменить выбор",
  color: "Цвет",
  statuses: {
    title: "Статусы",
    add: "Добавить статус",
    pick_color_for_status: "Выберите цвет для статуса",
    select: "Выберите статус",
    singular: "Статус",
    change: "Изменить статус",
  },
  holders: {
    title: "Держатели",
    add: "Добавить держателей",
    singular: "Держатель",
    select: "Выберите держатель",
    change: "Изменить держателя",
  },
  home: {
    title: "Главная",
  },
  company_type: "Тип компании",
  list_of_agreements: "Список договоров",
  edit_apartment: "Редактировать квартиру",
  changes_successfully_saved: "Изменения успешно сохранены.",
  yes_cancel: "Да, отменить",
  checkout_warning_before_cancel: "Вы уверены, что хотите отменить оформление?",
  this_action_cannot_be_undone: "Данное действие нельзя отменить.",
  to_all_contracts: "Ко всем договорам",
  download_all_contract: "Скачать все договоры",
  contract_completed_successfully: "Оформление договора прошло успешно",
  completion_of_registration: "Завершение оформлении",
  commentary: "Комментарий",
  comment_required_to_complete:
    "Для завершение оформлении необходим комментарий",
  country_code: "Код страны",
  country: "Страна",
  priority_countries: "Приоритетные страны",
  add_country: "Добавить страну",
  placeholder_uz: "На Узбекском",
  placeholder_ru: "На Русском",
  placeholder_eng: "На Английском",
  has_the_powers_of_vip: "Имеет полномочия V.I.P.",
  add_type: "Добавить тип",
  normal_client: "Oddiy mijoz",
  actions: "Действия",
  title: "Название",
  client_types: "Типы клиентов",
  return_all_apartments: "Вернуть все квартиры",
  cleaned_apartments: "Убранные квартиры",
  final_total_price: "Итоговая общая цена",
  fields_not_filled_out_or_incorrectly:
    "Поля, выделенные красным цветом, не заполнены или заполнены неправильно",
  fax: "Факс",
  legal_address: "Юридический адрес",
  ndc: "НДС",
  account_number: "Номер счета",
  bank: "Банк",
  company_name: "Название фирмы",
  client_information: "Информация о клиенте",
  payment_date: "Дата ежемесячного платежа",
  first_payment_date: "Дата первого платежа",
  payment_details_2: "Детали платежа",
  area: "Площадь",
  number_of_blocks: "Этажность блока",
  characteristics_of_the_apartment: "Характеристики квартиры",
  add_another_number: "Добавить еще номер",
  email: "Электронная почта",
  additional_email: "Дополнительная электронная почта",
  additional_phone_number: "Дополнителный номер телефона",
  client_contact_details: "Контактные данные клиента",
  communication_language: "Язык общения",
  place_of_issue__of_passport_id: "Место выдачи паспорта или ID",
  passport_series_example: "Серия и номер паспорта или ID (напр. AB1234567)",
  fio_full: "Фамилия имя отчество или среднее имя",
  nation: "Гражданство",
  person_type: "Субъект",
  preview: "Предросмотр",
  next_apartment: "Следующая квартира",
  physical_person: "Физическое лицо",
  legal_entity: "Юридическое лицо",
  clients_personal_data: "Личные данные клиента",
  prepayment: "Предоплата",
  please_select: "Пожалуйста выберите",
  installment: "Рассрочка",
  enter_discount: "Вариант оплаты",
  type: "Тип",
  total: "Итого",
  language: "Язык",
  additional: "Дополнительный",
  passport_series: "Номер паспорта",
  name: "Имя",
  second_name: "Отчество",
  last_name: "Фамилия",
  cyrillic_shortcut: "кир",
  latin_shortcut: "лат",
  passport_issue_date: "Дата выдачи паспорта",
  for_example: "например",
  remainder: "Остаток",
  apartment_detail: "Детали квартир",
  apartment_details: "Детали квартиры",
  payment_details: "Детали платежей",
  price_m: "Цена за М",
  price_m2: "Цена за M2",
  other_price: "Другая цена",
  remove_apartment: "Убрать квартиру",
  selected: "Выбранный",
  pointed: "Наведенный",
  not_selected: "Не выбранный",

  component: "компонент",
  import: "Импорт",
  found: "Найденные",
  not_found: "Не найденные",
  pin_menu: "Закрепить меню",
  hide_menu: "Скрыть меню",
  roles_permission: {
    activate_all: "Активировать все",
    placeholder_uz: "На Узбекском",
    placeholder_ru: "На Русском",
    placeholder_eng: "На Английском",
    slug: "Префикс имени группы",
    titles: {
      general: "Общие",
      objects: "Объекты",
      promos: "Акции",
      layouts: "Планировки",
      apartments: "Квартиры",
      execution: "Оформления",
      contracts: "Договоры",
      users: "Пользователи",
      roles: "Роли",
      debtors: "Должники",
      companies: "Компании",
      payment_account: "Счет",
      branches: "Филиалы",
      release_note: "Премичание к релизу",
      settings: "Настройки",
    },
    settings: {
      view: "Право просмотра страницы настроек",
      apartments: {
        holder: {
          view: "Право видеть, кто владелец квартиры",
          edit: "Право редактирования владельца квартиры",
        },
        status: {
          view: "Право просмотра «статуса» квартиры",
          edit: "Право редактирования статуса квартиры",
        },
      },
      holders: {
        create: "Право на создание держателей",
        update: "Право на редактирование держателей",
        delete: "Право на удаление держателей",
        view: "Право на просмотр страницы держателей",
      },
      statuses: {
        create: "Право на создание статусов",
        update: "Право на редактирование статусов",
        delete: "Право на удаление статусов",
        view: "Право просмотра страницы статусов",
      },
      client_types: {
        create: "Право на создание типов клиентов",
        update: "Право на редактирование типов клиентов",
        delete: "Право на удаление типов клиентов",
        view: "Право просмотра страницы типов клиентов",
      },
      client_countries: {
        create: "Право на создание стран клиента",
        update: "Право на редактирование стран клиента",
        delete: "Право на удаление стран клиента",
        view: "Право просмотра страницы стран клиента",
      },
      contracts: {
        main_manager: "Главный менеджер",
      },
    },
    general: {
      role_name_ru: "Название роли (RU)",
      role_name_uz: "Название роли (UZ)",
      exchange_rates: "Курс валют",
      theme: "Тема (темная, светлая)",
      language: "Язык (русский, узбекский)",
      profile_settings: "Настройки полфиля",
      user_data: "Право изменть данные пользователя",
      user_password: "Право изменять пароль пользователя",
    },
    objects: {
      watch_objects: "Право на просмотр страницу объектов",
      create_object: "Право на создания объекта",
      edit_object: "Право на редактирования объектов",
      show_object: "Право на просмотр одного объекта",
      delete_object: "Право на удаление объекта",
      download_logo: "Право загрузить логотип",
    },
    promos: {
      watch_promos_page: "Право на просмотр страницу акции",
      create_promo: "Право на создания акции",
      edit_promo: "Право на редактирования акции",
      delete_promo: "Право на удаление акции",
    },
    layouts: {
      watch_layouts: "Право на просмотр страницу планировки",
      create_layouts: "Право на создания планировки",
      edit_layouts: "Право на редактирования планировки",
      delete_layouts: "Право на удаление планировки",
    },
    apartments: {
      watch_apartments:
        "Право просмотра списка квартир (страница одного объекта)",
      filter_apartments:
        "Право пользования фильтром на странице списка квартир",
      edit_apartment: "Право редактировать квартиру (страница одного объекта)",
      status_apartment:
        "Право снять с продажи и вернуть в продажу квартиру (страница одного объекта)",
      change_list_apartments:
        "Право пользования списком для изменения вида списка квартир",
      list_apartments1:
        "Право пользования шахматкой 1.0 для изменения вида списка квартир",
      list_apartments2:
        "Право пользования шахматкой 2.0 для изменения вида списка квартир",
      using_layouts:
        "Право пользования планировкой для изменения вида списка квартир",
      update_comments: "Право на редактирования комментов",
      delete_comments: "Право на удаления комментов",
      create_comments: "Право на создания комментов",
      read_comments: "Право просмотра комментов",
    },
    execution: {
      book_apartment: "Право забронировать квартиру (страница одного объекта)",
      decorate_apartment: "Право оформления квартиры",
      tag_acquaintances: "Право отмечать знакомых",
      change_payment: "Право оформить договор по другой цене",
      edit_date: "Право оформить договор по другой дате",
      change_monthly_payment:
        "Право оформить договор по другому ежемесячному платежу",
      full_access: "Полный доступ",
    },
    contracts: {
      watch_deals: "Право просмотра списка договоров",
      filter_deals: "Право пользования фильтром списка договоров",
      watch_deal: "Право на просмотр одного договора",
      download_deal: "Право скачивания договоров",
      decline_deal: "Право отменить договоров",
      client_type: "Право изменить тип клиента (вкладка 'Детали клиента')",
      watch_branch_deals:
        "Право просмотра все договора филиала (менеджер филиала)",
      import: "Право скачать оплаты",
      list: "Право просмотра оплаты",
      change_date_deal: "Разрешить изменить дату договора",
      edit_monthly_payment: "Разрешить редактировать ежемесячных платежей",
      edit_payment: "Право на редактирования оплаты (страница одного договора)",
      delete_payment: "Право на удаление оплаты (страница одного договора)",
      add_payment: "Право добавить оплату (страница одного договора)",
      create_type_payment: "Право на создание первоначального типа оплаты",
      edit_type_payment: "Право на редактирования первоначального типа оплаты",
      delete_type_payment: "Право на удаления первоначального типа оплаты",
      create_monthly_payment: "Право на создание ежемесячного типа оплаты",
      edit_monthly_type: "Право на редактирования ежемесячного типа оплаты",
      delete_monthly_type: "Право на удаления ежемесячного типа оплаты",
      reissue_view: "Право на предпросмотр переоформление",
      reissue_create: "Право создания переоформление",
      uniformity: "Право просмотра неравномерностей",
      comments: "Право писать коментарии в договоры",
      edit: "Право на редактирования договоров",
      full_access: "Полный доступ",
      update_comments: "Право на редактирования комментов",
      delete_comments: "Право на удаления комментов",
      create_comments: "Право на создания комментов",
      read_comments: "Право просмотра комментов",
      download_order: "Право загрузить список заказов",
      download_report: "Право загрузить список отчетов",
    },
    users: {
      watch_users: "Право просмотра списка пользователей",
      create_user: "Право добавление пользователя",
      edit_user: "Право редактировать пользователя",
      delete_user: "Право удалять пользователя",
    },
    release_note: {
      watch_notes: "Право на просмотр примечаний к выпуску",
      create_note: "Право на создание примечания к выпуску",
      edit_note: "Право на редактирование примечания к выпуску",
      delete_note: "Право на удаление примечания к выпуску",
    },
    roles: {
      watch_roles: "Право просмотра списка ролей",
      add_roles: "Право добавления ролей",
      edit_roles: "Право редактирования ролей",
      delete_roles: "Право удалении ролей",
    },
    debtors: {
      watch_debtors: "Право просмотра списка должников",
    },
    companies: {
      watch_companies: "Право просмотра списка компаний",
      watch_company: "Право просмотра одной компании",
      create_company: "Право добавления компании",
      edit_companies: "Право редактирования компании",
      delete_company: "Право удаления компанию",
    },
    payment_account: {
      access_payments_list: "Право просмотра списка рассчетных счетов",
      add_payment: "Право добавления рассчетнего счета",
      edit_payment: "Право редактирования рассчетнего счета",
      delete_payment: "Право удаление рассчетнего счета",
    },
    branches: {
      watch_branches: "Право просмотра списка филиалов",
      edit_branch: "Право редактирования филиала",
      delete_branch: "Право удаления филиала",
      add_branch: "Право добавления филиала",
      watch_contract_template: "Право просмотра шаблона договора",
      create_contract_template: "Право создании шаблона договора",
      delete_contract_template: "Право удалении шаблона договора",
      make_main_branch_template:
        "Право создании шаблона договора основных филиалов",
      template_download: "Право скачивание шаблона договора",
      template_instruction: "Право просмотра инструкци шаблона договора",
    },
    reports: {
      create: "Право создавать отчеты",
      view: "Право на просмотр отчетов",
    },
  },
  xonsaroy: "Хонсарой",
  discount_per_m: "Скидка за M",
  discount_per_m2: "Скидка за M2",
  checkout_booking: "Оформление",
  apartment_make_contract: "Оформление квартиры",
  address_office:
    "город Ташкент, Юнусабадский район, улица Янги шахар, дом 64А",
  motto: "Уверенный шаг к вашим мечтам!",
  quarter: "четверть",
  of_the_year: "года",
  no_cancel: "Нет, отменить",
  yes_delete: "Да, удалить",
  chosen: "Выбрано",
  remove_payment: "Удалить оплату ?",
  deleted: "Удалено",
  successfully: "Успешно!",
  payment_change: "Платеж изменен",
  other: "Другие",
  booked: "Забронировать",
  not_for_sale: "Hе продается",
  remove_from_sale: "Снять с продажи",
  return_to_sale: "Вернуть к продаже",
  edit: "Редактировать",
  edited: "изменено",
  upload_logo: "Загрузить логотип",
  loading: "Пожалуйста подождите...",
  add: "Добавить",
  id: "Id",
  delete: "Удалить",
  action: "Действие",
  search: "Найти",
  save: "Сохранить",
  create: "Создать",
  view: "Посмотреть",
  cancel: "Отменить",
  back: "Назад",
  next: "Продолжить",
  create_agree: "Оформить",
  create_agree_apartments: "Оформить квартиры",
  save_changes: "Сохранить изменения",
  reason_recontract: "Причина переоформлении",
  number_agree: "Номер соглошения",
  recheck: "Предпросмотр",
  create_date: "Дата cоставления",
  recontract_details: "Детали переоформлении",
  contract_log: "Журнал активности",
  recontract_download: "Скачать переоформленный договор",
  re_contract: "Переоформить",
  leave_import: "Выйти из импорта?",
  leave_import_text:
    "Вы уверены, что хотите отменить и выйти из импорта? </br>" +
    "Все примененные изменения будут сброшены.",
  no_leave: "Нет, остаться",
  yes_leave: "Да, выйти",
  yes: "Есть",
  no: "Нет",
  image: "Изображения",
  address: "Адрес", // 'settings': 'Настройки',
  no_data: "Нет данных",
  logout: "Выйти",
  list: "Список",
  close: "Закрыть",
  ye: "сум",
  usd: "$",
  _usd: "USD",
  uzs: "UZS",
  price_from: `от {msg} сум`,
  price_from_m2: `от {msg} сум/м<sup>2</sup>`,
  price_sold_m2: "Цена продажи за {msg}:",
  total_price: "Общая цена",
  general: "Общие",
  edit_password: "Изменить пароль",
  profile: "Профиль",
  category: "Категория",
  full: "Полный",
  monthly: "Ежемесячно",
  month: "Месяцев",
  month_lowercase: "месяцев",
  months: "Месяцы",
  months_to: "месяцев по",
  table_months_payment: "Таблица ежемесячных платежей",
  duration: "Продолжителность",
  without_initial: "Без первоначального платежа",
  payment_type: "Способ оплаты",
  created_at: "Время создания",
  reservation: "Бронирование",
  more_info: "Подробная информация",
  activate: "Активировать",
  deactivate: "Деактивировать",
  monthly_payment: "Ежемесячный платеж",
  initial_payment: "Первоначальный",
  monthly_pay: "Ежемесячный",
  sum_text: "В суммах",
  sum: "Сумма",
  usd_text: "В долларах",
  text: "Текст",
  apartments_count: "Количество квартир",
  apartment_list: "Список квартир",
  contract_file_not_found: "Файл контракта не найден",
  instruction: "Инструкция",
  validation_warning_to_bottom:
    "Одно из полей заполнено неправильно, пожалуйста проверьте все поля еще раз!",
  new_name: "Новое название",
  meaning: "Значение",
  example_by_2: "Примеры(по 2)",
  payment_schedule: "График оплаты",
  object_details: "Детали объекта",
  client_details: "Детали клиента",
  client_info: "Информация клиента",
  contract_details: "Детали договора",
  comment: "Комментария",
  floor: "Этаж",
  paid: "Оплачено",
  waiting_to_payment: "На оплате",
  partially: "Частично оплачено",
  cash: "Наличные",
  error: "Ошибка!",
  view_map: "Показать на карте",
  object_map: "Локация объекта",
  object_create: "Добавить объект",
  main: "Основные",
  main_number: "основной",
  passport: "Паспортные данные",
  builder: "Застройщик",
  type_payment: "Виды оплат",
  details: "Детали",
  other_details: "Другие детали",
  fio: "Ф.И.О",
  phone: "Номер телефона",
  other_phone: "Дополнительный номер телефона",
  extra: "дополнительный",
  client_type: "Тип клиента",
  birth_day: "Дата рождения",
  series: "СЕРИЯ",
  given: "ВЫДАН",
  given_date: "ДАТА ВЫДАЧИ",
  executor: "Исполнитель",
  company: "КОМПАНИЯ",
  agent: "ПРЕДСТАВИТЕЛЬ",
  pc: "Р/С",
  inn: "ИНН",
  mfo: "МФО",
  number: "Номер",
  contract_number: "НОМЕР ДОГОВОРА",
  contract_price: "СУММА ДОГОВОРА",
  date: "Дата",
  formed: "ОФОРМИЛ",
  status: "СТАТУС",
  familiar: "Знакомый",
  unfamiliar: "Незнакомый",
  continue_registration: "Продолжить оформление",
  apartment: "Квартира",
  from: "от",
  to: "до",
  square_meter: "m<sub>2</sub>",
  apartment_number: "Номер квартиры",
  completion_date: "Дата завершения",
  plan_area: "Площадь планировки",
  balcony: "Балкон",
  number_of_rooms: "Количество комнат",
  number_of_floors_of_the_block: "Количество этажей в блоке",
  search_placeholder: "ФИО, телефон, номер договора",
  apartment_rooms: "Найдено { msg } помещений",
  price_monthly: "{month} месяцев от {price} сумов",
  total_discount: "Общая скидка",
  starting_price: "Начальная цена",
  characters: "Характеристики",
  clear: "Очистить",
  selling_price: "Цена продажи",
  payment_discount: "Вариант оплаты",
  by_price: `По {price} сум`,
  by_price_m2: "По {price} сум/M.",
  selected_variant: "Выбранный вариант оплаты",
  other_variant: "Другие варианты оплат",
  contract_number_or_full_name: "Номер договора или ФИО",
  activity_type_full_name: "ФИО или тип активности",
  base_price_from: "Сумма (от)",
  base_price_to: "Сумма (до)",
  client: "Клиент",
  debt_details: "Детали долга",
  debt: "Долг",
  go_to_contract: "Перейти к договору",
  week: "Неделя",
  today: "Сегодня",
  day: "День",
  refresh_password: "Обновить пароль",
  manager: "Менеджер",
  contract_regeneration: "Регенерация контракта",
  lotin_compress: "(лат.)",
  cyrill_compress: "(кир.)",
  details_of_the_agreement: "Детали соглашения",
  date_of_the_agreement: "Дата составления",
  agreement_number: "Номер соглашения",
  reason_for_reissuing: "Причина переоформлении",
  apply: "Применить",

  monday: "Понедельник",
  tuesday: "Вторник",
  wednesday: "Среда",
  thursday: "Четверг",
  friday: "Пятница",
  saturday: "Суббота",
  sunday: "Воскресенье",

  january: "Январь",
  february: "Февраль",
  march: "Март",
  april: "Апрель",
  may: "Май",
  june: "Июнь",
  july: "Июль",
  august: "Август",
  september: "Сентябрь",
  october: "Октябрь",
  november: "Ноябрь",
  december: "Декабрь",

  weekDayAbbreviation: {
    0: "ВС",
    1: "ПН",
    2: "ВТ",
    3: "СР",
    4: "ЧТ",
    5: "ПТ",
    6: "СБ",
  },

  more: "Еще",
  go_to_day: "Перейти к дню",

  tab_status: {
    all: "Все",
    available: "Доступные",
    booked: "Забронирован",
    sold: "Оформлен",
    unavailable: "Недоступные",
    promo: "Акция",
    on_payment: "На оплате",
    closed: "Закрыт",
    reorder: "Переоформленные",
    active: "Активный",
    de_active: "Деактив",
    archived: "Архивировано",
    deleted: "Удалено",
  },

  promo: {
    name: "Акция",
    promos: "Акции",
    table_name: "Название",
    blocks: "Блоки",
    floors: "Этажи",
    creation_title: "Создание акции",
    edit_title: "Редактировать акции",
    modal_input_text_label: "Название акции",
    date_of_start_title: "Дата начала акции",
    date_of_end_title: "Акция действует до",
    date_of_start_label: "Акция будет действовать с начала выбранной даты",
    date_of_end_label: "Срок акции истекает в этой дате",
    select_block: "Выберите блок(и)",
    select_floor_plan: "Выберите этажи, планировки или квартиры в блоках",
    select_price: "Цена по акции (за м2)",
    select_type: "Выберите тип акции",
    select_sum: "В суммах",
    select_by_floor: "По этажам",
    select_by_apartment: "По квартирам",
    select_by_plan: "По планировке",
    add_price: "Добавить цену",
    warn_before_delete: "Вы действительно хотите удалить акцию",
    successfully_deleted: "Акция успешно удалена",
    successfully_created: "Акция успешно создана",
    successfully_edited: "Акция обновлена",
    alert_before_close_title: "Вы действительно хотите закрыть это окно?",
    alert_before_close_no: "Нет",
    by_promo: "По акции",
    in_promo: "В акции",
    promo_rate: "Тариф",
    initial_price: "Первоначальный взнос (%)",
  },

  promos: "Акции",
  go_back: "Назад",
  auth: {
    email: "E-mail",
    password: "Пароль",
    login: "Войти",
    validation_email: "Неправильный формат электронной почты",
    validation_password: "Пароль должен содержать не менее 8 символов",
  },

  messages: {
    created: "Успешно создано!",
  },

  notifications: {
    title: "Уведомления",
  },

  payments: {
    payment_total: "Общий оплачено",
    payment: "Сумма договора",
    course: "Курс",
    balance: "Остаток",
    initial_fee: "Первоначальный взнос",
    initial_fee_pay: "Оплаченный первоначальный взнос",
    installment: "Рассрочка",
    month: "месяцев",
    course_name: "сум",
    contract: "Договор",
    payment_list: "Список оплат",
    collapsed_payment_list: "СО",
    payment_download: "Импорт оплат",
    payment_add: "Добавить оплату",
    import: "Загрузите файл в формате .xls или .csv для импорта оплат",
    drag_file: "Перетащите файл в эту область или",
    click_file: "нажмите для выбора файла",
    download_template: "Скачать шаблон",
    table: {
      date: "Дата",
      balance: "Сумма",
      type: "Тип",
      method: "Способ",
      comment: "Комментарий",
      actions: "Действия",
    },
  },

  contracts: {
    name: "Договоры",
    list_contracts: "Список договоров",
    collapsed_contracts_list: "СД",
    object_name: "Название объекта",
    apply_filter: "Применить",
    client_type: "Тип клиента",
    date_type: "Тип действия",
    reset_filter: "Сбросить",
    agreement_date: "Дата договора",
    apartment_number: "Номер квартиры",
    filter_title: "Фильтры поиска",
    transfer: "Перечисления",
    warning: "Внимание",
    edit_payment: "Редактировать оплату",
    add_payment_successfully: "Оплата проведена",
    deleted_payment_successfully: "Оплата успешно удалена",
    comment: "Комментарий",
    warn_before_delete_contract:
      "Вы уверены, что хотите удалить договор? Данное действие нельзя отменить.",
    comment_delete_contract: "Укажите причину удаления",
    warn_before_delete_payment:
      "Вы уверены, что хотите удалить оплату? Данное действие нельзя отменить.",
    warning_in_payment:
      "Первоначальный взнос имеет излишек в размере {price} сумов. Вы хотите перевести данную сумму в ежемесячный платеж?",
    title: "Договоры",
    download: "Скачать договор",
    f_i_o: "Ф.И.О", // asd
    filter_with_date: "Фильтр по дате", // asd
    client_name: "Имя клиента", // asd
    client_phone: "Номер телефона", // asd
    price: "Стоимость", // asd
    object: "Объект", // asd
    show_by: "Показать по",
    search: "ФИО, телефон, номер договора",
    filter: "Фильтры поиска",
    add_note: "Добавить примечание",
    note: "Примечание",
    notes: "примечаний",
    no_notes: "Нет примечаний",
    no_note: "На данный момент нет примечаний",
    delete_note: "Удалить примечание?",
    note_delete_confirmation:
      "Вы уверены, что хотите удалить примечание?\n" +
      "Данное действие нельзя отменить.",
    edit_note: "Редактировать примечание",
    note_text: "Текст примечание",
    individual: "индивидуальный",
    table: {
      contract: "Договор",
      client: "Клиент",
      phone_number: "Телефон",
      status: "Статус",
      cost: "Стоимость",
      object: "Объект",
      date: "Дата",
    },
    activity_log: {
      reissue: "в переоформление",
      comments: "в комментарие",
      payments_histories: "в историе оплаты",
      activity_type: "Тип активности",
      orders: "в договоре",
      edited_file: "Изменённый файл",
      actions: {
        deleted: "Удаление",
        reissue: "Переоформление",
        created: "Создание",
        edited: "Редактирование",
        updated: "Редактирование",
      },
    },

    status: {
      waiting: "На оплате",
      booked: "Забронирован",
      sold: "Продан",
      closed: "Закрыт",
      contract: "Оформлен",
      cancelled: "Отменен",
    },

    view: {
      regenerate_warning:
        "Контракт может быть переоформлен только один раз. Уточните всю информацию, прежде чем продолжить",
      regenerate_select_reason: "Выберите причину переоформлении",
      unfamiliar: "Незнакомый", // asd
      familiar: "Знакомый", // asd
      edit_type: "Редактировать тип", // asd
      save_btn: "Сохранить", // asd
      tariff: "Тариф", // asd
      dollar_rate: "Курс доллара", // asd
      initial_fee: "Первоначальный взнос", // asd
      apartment_price: "Цена квартиры", // asd
      remainder: "Остаток", // asd
      add_payment: "Добавить платеж", // asd
      schedule: "Расписание", // asd
      sum: "Сумма", // asd
      cancel_btn: "Отменить", // asd
      type: "Тип", // asd
      comment: "Комментарий", // asd
      payment_type: "Тип оплаты", // asd
      payment_date: "Дата оплаты", // asd
      payment_sum: "Сумма оплаты", // asd
      paid: "Оплачено", // asd
      comments: "Комментарии", // asd
      no_comment: "Пока комментариев нет", // пока нету комментариев
      add_comment: "Добавить комментарий", // asd
      download_contract: "Скачать договор", // asd
      update_contract: "Редактировать договор", // asd
      cancel_contract: "Отменить договор", // asd
      payment_by_cash: "Наличные", // asd
      payment_transaction: "Перечисление", // asd
      payment_other: "Другое", // asd
      initial_payment: "Первоначальный взнос", // asd
      actions: "Действия",
    },
  },
  release_note: {
    add_release_note: "Добавить примечания к выпуску",
    release_note: "Примечания к выпуску",
    version_number: "Номер версии",
    texts: "Тексты",
    new: "Новый",
    published: "Опубликовано",
    comment:
      "Вы всегда можете найти примечания к выпуску в настройках, щелкнув номер версии.",
  },
  roles: {
    title: "Роли",
    name: "Название",
    users: "Пользователи",

    permissions: {
      name_uz: "Название роли (UZ)",
      name_ru: "Название роли (RU)",

      placeholder_uz: "На Узбекском",
      placeholder_ru: "На Русском",
      placeholder_eng: "На Английском",

      slug: "Префикс имени группы",

      objects: {
        view: "Разрешить просмотр объектов",
        create: "Разрешить создать объект",
        update: "Разрешить редактировать объект",
        delete: "Разрешить удалять объекты",
        apartments: "Разрешить видеть список квартир",
      },

      users: {
        view: "Разрешить видеть пользователей",
        create: "Разрешить создать пользователей",
        update: "Разрешить редактировать пользователей",
        delete: "Разрешить удалять пользователей",
      },

      roles: {
        view: "Разрешить видеть роли",
        create: "Разрешить создать роли",
        update: "Разрешить редактировать роли",
        delete: "Разрешить удалять роли",
      },

      contracts: {
        view: "Разрешить видеть договоры",
        all: "Разрешить всем договорам доступ",
        friends: "Разрешить отмечать знакомые",
        cancelled: "Разрешить отменять контракты",
        paid: "Разрешить отмечать оплаты",
        other_price: "Разрешить продать по другой цене",
        change_date: "Разрешить изменить дату договора",
        monthly: "Разрешить редактировать ежемесячных платежей",
      },

      clients: {
        view: "Разрешить просмотр клиентов",
        cancel_contract: "Разрешить отменять бронь",
        delete: "Разрешить удалять клиентов",
        terminate: "Разрешить отменять контрактов",
        cancelled: "Разрешить видеть отмененных контрактов",
      },

      type_plan: {
        view: "Разрешить видеть планировок",
        update: "Разрешить редактировать планировки",
      },

      apartments: {
        view: "Разрешить просмотр квартиры (внутрь квартиры)",
        reserve: "Разрешить бронировать квартиры",
        contract: "Разрешить оформлять контракты",
        filter: "Разрешить сортировать квартиры (Filter)",
        edit: "Разрешить редактировать квартиры (Номер, комнат, Площадь и т.д)",
        root_contract: "Дать полный доступ контрактам",
      },

      debtors: {
        first_payment: "Разрешить принимать первоначальную оплату",
        first_payment_edit: "Разрешить редактировать первоначальную оплату",
        monthly_payment: "Разрешить принимать ежемесячных платежей",
        monthly_payment_edit: "Разрешить редактировать ежемесячных платежей",
      },

      // 'companies': {
      view: "Разрешить просмотреть компании",
      create: "Разрешить создать компанию",
      update: "Разрешить редактировать компанию",
      delete: "Разрешить удалять компанию", // },
    },

    admin: "Администратор",
    manager: "Менеджер",
    accountant: "Бухгалтер",
    executor: "Исполнитель",
    sales_department: "Отдел продаж",
    security_manager: "Менеджер по безопасности",
  },

  clients: {
    title: "База клиентов",
    fio: "ИМЯ КЛИЕНТА",
    phone: "Телефон",
    amount: "СТОИМОСТЬ",
    apartment: "КВАРТИРА",
    object: "ОБЪЕКТ",
    file: "ФАЙЛ договора",
    number: "№ Договора",
    language: "Язык",
    date: "Дата", // search: "Найти по номеру договора или по номеру телефона клиента",
    search: "Найти по номеру договора",
  },

  companies: {
    title: "Компании",
    name: "Компания",
    director: "Директор",
    type: "Тип компании",
    number: "Номер",
    inn: "ИНН",
    mfo: "МФО",
    phone: "Номер телефона",
    other_phone: "Дополнительный номер телефона /факс",
    payment_account: "Расчетный счет",
    bank_name: "Названия банка",
    first_name: "Имя директора",
    last_name: "Фамилия директора",
    second_name: "Отчество директора",
    accounts_number: "Количество счетов",
    type_enter: "Тип компании",
    branch_enter: "Выберите компанию",
    address: "Адрес компании",
    code: "Регистрационный код плательщика",
    oked: "ОКЭД",
    addPayment: "Добавить счет",
    bank_name_uz: "Название банка (Узб.)",
    bank_name_ru: "Название банка (Рус.)",
    check_account: "Расчетный счет",
    more: "Реквизиты компании",
    actions: "Действия",
    add_company: "Добавить компанию",
    add_title: "Добавить заглавие",
    search: "Название компании",
    active_payment: "Активный счет",
    inactive_payment: "Неактивный счет",
    delete_company: "Удалить компанию",
    fax_number: "Номер телефона / факс",
    counted_payment: "Расчетные счета",
  },

  apartments: {
    title: "Квартиры",
    clone_tariff: "Дублировать тариф",
    delete_tariff: "Удалить тариф",
    add_tariff: "Добавить тариф",
    first_payment: "Первый взнос",

    status: {
      cancelled: "Отменен",
      waiting: "В процессе",
      contract: "Оформлено",
      sold: "Продано",
      booked: "Забронировано",
      hold: "Оставайтесь",
      closed: "Закрыто",
      available: "Свободно",
      unavailable: "Hе продается",
      decorated: "Оформлено",
      not_available: "Недоступно",
    },

    list: {
      choose: "Выбрать",
      reset: "Сбросить",
      contract_all: "Оформить все",
      number: "№",
      object: "Объект",
      rooms: "Количество комнат",
      floor: "Этаж",
      area: "Площадь",
      status: "Статус",
      price: "Цена",
      building: "Здание",
      block: "Корпус",
      book: "Забронировать",
      confirm: "Оформить",
      filter: "Фильтр",
      apartments: "Квартиры",
      contract: "Договор",
      entrance: "Подъезд",
      balcony: "Балкон",
      period_date: "Период бронирования",
      more: "Подробная информация",
      cancel_contract: "Отменить контракт",
      download_contract: "Скачать контракт",
      view_client: "Посмотреть клиента",
      cancel_reserve: "Отменить бронь",
      view_manager: "Информация о менеджере",

      price_sold: "Была продана за",
    },

    filter: {
      apartments: "Количество комнат",
      floor: "Этаж",
      ot: "от",
      do: "до",
      price: "По цене",
      area: "По площади",
      object: "Объект",
      status: "По статусу",
      free: "Только свободные",
      usd: "Цена в долларах",
      clear: "Сбросить",
      blocks: "Блоки",
      contract_number: "Номер контракта", // asd
      apartment_number: "Номер квартиры", // asd
      filter_btn: "Фильтровать", // asd
    },

    view: {
      plan: "Планировка",
      number: "№ Дома",
      area: "Площадь",
      rooms: "Количество комнат",
      floor: "Этаж",
      price_m2: "Цена за 1м2",
      total_price: "Цена продажи",
      status: "Статус",
      variant: "Вариант оплаты",
      other_variant: "Другой вариант",
      enter_discount: "Выберите вариант оплаты:", // agree.placeholder
      number_of_blocks: "Этажность блока", // asd
      object: "Объект", // asd
      address: "Адрес", // asd
      completion_date: "Дата завершения строительства", // asd
      price_for_m2: "Цена продажы за м2", // asd
      discount_per_m2: "Скидка за м2", // asd
      discount_all: "Общая скидка",
      discount_price: "Скидка от общей суммы",
      prepayment: "Предоплата", // asd
      print: "Печатать", // asd
    },

    agree: {
      number: "Номер договора",
      date_contract: "Дата договора", // (месяц/число/год)
      fio: "ФИО",
      first_name: "Имя",
      last_name: "Фамилия",
      second_name: "Отчество",
      passport_series: "Номер паспорта",
      issued_by_whom: "Место выдачи паспорта",
      language: "Язык",
      phone: "Номер телефона",
      type_client: "Тип клиента",
      other_phone: "Дополнительный номер телефона",
      date_of_issue: "Дата выдачи пасспорта",
      birth_day: "Дата рождения", //(месяц/число/год)
      discounts: "Тип покупка",
      edit_price: "Редактировать цену",
      edit_prices: "Редактировать цены",
      first_payment_date: "Дата первого платежа",
      payment_date: "Дата ежемесячного платежа",
      placeholder: {
        number: "D-6",
        date_contract: "20.12.2020",
        search: "Найти по номеру телефона или по серии паспорта",

        first_name: "пример: Ахмад",
        last_name: "пример: Жасуров",
        second_name: "пример: Хакимович",

        first_name_lotin: "ex: Axmad",
        last_name_lotin: "ex: Jasurov",
        second_name_lotin: "ex: Khakimovich",

        passport_series: "пример: AA1234567",
        issued_by_whom: "пример: Тошкент шахар Олмазор тумани И.И.Б",
        phone: "пример: +99899 123 45 67",
        other_phone: "пример: +99899 765 43 21",
        enter_discount: "Выберите вариант оплаты",
      },
    },

    tab_names: {
      all: "Все", //asd
      available: "Доступный", //asd
      booked: "Забронирован", //asd
      sold: "Оформлен", //asd
      unavailable: "Недоступен", //asd,
      promo: "Акция",
    },
  },

  type_plan: {
    floor: "Этаж",
    image: "Внешность",
    connect_plan: "Связанная планировка",
    rooming: "Комнатность",
    title: "Планировки",
    actions: "Действия",
    name: "Название",
    area: "Площадь",
    balcony: "Балкон",
    no_img: "(нет планировки)",
    yes_img: "(Планировка существует)",
    plan: "Планировка",
    plans: "Все планировки",
    list: "Список планировок",
    plan_required: "Вам необходимо выбрать проект квартиры",
    delete_title:
      "Вам нужно перейти на другой план, который имеет {apartments_count} квартир в этом плане квартир.",
  },

  debtors: {
    title: "Должники",
    overdue: "Просроченные", // asd
    familiars: "Знакомые", // asd
    new_title: "Задолжники",
    collapsed_new_title: "З",
    contract_number: "№ договора", // asd
    client_name: "имя клиента", // asd
    client_number: "номер телефона", // asd
    client_type: "тип клиента", // asd
    import_debtors: "Импорт долгов",
    import_text: "Загрузите файл в формате .xls или .csv для импорта долгов",
    file_field: "Поля файла",
    property: "Свойство",
    file: "Поле в файле",
    contract_file: "Договор в файле",
    contract_system: "Договор в системе",
    alias_system: "Псевдоним контракта в системе",
    import_leave: "Вы можете потерять данные. Вы хотите выйти?",
  },

  backup: {
    title: "Резерв",
  },

  object: {
    home: "ДОМ",
    entrance: "ПОДЪЕЗД",
    level: "Этаж",
    number_level: "ЭТАЖНОСТЬ",
    flat: "КВАРТИРА",
    number_flat: "КОЛ-ВО КОМНАТ",
    area: "ПЛОЩАДЬ",
    balcony: "БАЛКОН",
    complete: "ЗАВЕРШЕНИЕ СТРОИТЕЛЬСТВА",
    sort: {
      flat: "Комнат",
      area: "Жилая площадь",
      block: "Блок",
      number_flat: "Номер квартиры",
      number_rooms: "Номер помещения",
      apply: "Применить",
      clear: "Очистить",
    },
    chess: "Шахматка",
    list: "Список",
    plan: "Планировки",
    flats: "комнаты",
    rooms: "помещений",
    status: {
      available: "Свободно",
      sold: "Продано",
      booked: "Забронировано",
      contract: "Оформлено",
      disable: "Hе продается",
      hold: "Оставайтесь",
    },
  },

  objects: {
    title: "Объекты",
    apartments: "Квартиры",
    address: "Адрес",
    full_address: "Адрес для договора",
    build_date: "Дата окончания строительства",
    credit_month: "Месяц на рассрочку",
    deal_file: "Файл контракта",
    select_category: "Выбрать категорию",
    sale: "Продажа",
    booking: "Бронирование",
    contract: "Оформить",
    make_it_main_contract: "Сделать основным договором",
    unfinished_contract: "Незавершенный контракт",
    view_apartments: "квартиры",
    view_level: "этажей",

    deal_template: {
      name: "Шаблон договора",
      type: "Тип",
      main: "Главный",
      title: "Список шаблонов договоров",
      warning_message:
        "Вы не выбрали основные договоры! Пожалуйста выберите все основные договоры!",
    },

    create: {
      name: "Название",
      blocks_apartment: "КВАРТИРНЫЕ БЛОКИ",
      copy_block: "Клонировать блок",
      copy_building: "Клонировать здание",
      balcony_price: "Цена за балкон",

      new: "Создать новый объект",
      type_plan: "Типы планировок",
      enter_press: "Нажмите Enter чтобы выбрать",
      no_type_plan: "нет планировки",
      new_type_plan: "Создать планировку",
      entrance: "Подъезд",
      other_price: "Другая цена за м2",
      price_m2: "Цена за м2",
      price_update: "Обновить цену",
      clone_apartments: "Клонировать этаж",
      clone: "Клонировать",
      choose_clone: "С какого этажа клонировать?",

      plan: {
        name: "Планировка",
        area: "Площадь",
        balcony: "Имеется балкон?",
        balcony_area: "Площадь балкона",
        balcony_paid: "Платный балкон?",
        add: "Добавить планировку",
        search: "Название планировки",
        add_image: "Добавить рисунок",
      },

      fast_plan: {
        name: "Чертежи планировок",
        add: "Добавить чертеж планировки",
        choose_apartments: "Выберите связанную квартиру",
        add_plan: "Добавить чертеж",
        select_room: "Выбрать квартиру",
        search: "Название файла или связанной планировки",
        import_text:
          "Загрузите файл в формате .pdf или .png для чертежа планировки",
      },

      apartments: "Квартиры",
      floors: "Этажи",

      blocks: "Блоки",
      buildings: "Здания",
      new_block: "Новый блок",
      edit_block: "Редактировать блок",
      count_floors: "Количество этажей",
      count_apartments: "Количество квартир",

      price_area: "Цена за м2",
      new_price: "Новая цена",
      create_price: "Создать цену",
      alert_price:
        "Чтобы продолжить добавления, пожалуйста добавьте цены этажей",
      prices: "Цены",

      apartment: "Квартира",
      floor: "Этаж",
      type_plan_apartment: "Тип планировки",
      rooms: "Кол-во комнат",
      area: "Площадь",
      price: "Цена",

      pre_pay: "Предоплата",
      discount: "Сумма скидки",

      new_rules_discount: "Новые условия скидки",

      prepay_from: "от",
      prepay_to: "до",

      create_build: "Добавить здание",
      create_discount: "Добавить скидку",
      discounts: "Тарифы продаж",

      choose_plan: "Выберите планировку",

      tariff: {
        main: "Оснавной",
        type: "Тип тарифа",
        type_name: "Название тарифа",
        price: "Цена",
        price_balcony: "Цена с балконом",
        add_price: "Добавить цену",
        floor_placeholder: "Этажи или спецификации",
        add_percent: "Добавичный процент",
        fixed: "Фиксированный",
        percent: "Процентный",
        addition: "Разделенный",
      },
    },

    placeholder: {
      name: "Названия объекта",
      address: "Адрес объекта",
      full_address: "Адрес для договора",

      type_plan: "Название планировки",

      block_name: "Названия блока",
      build_date: "20.12.2020",
      building_name: "Названия корпуса",
      credit_month: "Месяц на рассрочку",
    },
  },

  building_layout: {
    add_layout: "Добавить планировку",
    layout_name: "Название планировки",
    add_title: "Добавить заглавие",
    floors: "Этаж(и)",
    upload: {
      add_image: "Добавить рисунок",
      add_video: "Добавить видео",
      add_file: "Добавить файл",
      add_audio: "Добавить аудио",
    },
  },

  sweetAlert: {
    title: "Вы уверены что хотите выполнить это действие?",
    text: "Вы действительно хотите удалить?",
    text_copy_block: "Вы действительно хотите клонировать этаж?",
    yes: "Да, удалить его",
    yes_close: "Да, закрыть",
    yes_agree: "Да, заключить",
    yes_clone: "Да, клонировать",
    no: "",
    deleted: "Удалено!",
    text_cancel_contract: "Вы действительно хотите расторгнуть договор?",
    yes_cancel_contract: "Да, расторгнуть",
    canceled_contract: "Контракт расторгнуть",
    payment_success: "Оплата успешно принята",
    payment_success_added: "Оплата успешно создана",
    payment_list_add: "Успешно добавлено в список платежей",

    text_cancel_agree: "Вы действительно хотите закрыть?",
    text_agree: "Вы действительно хотите заключить договор ?",

    text_cancel_reserve: "Вы действительно хотите отменить бронь?",
    yes_cancel_reserve: "Да, отменить",
    canceled_reserve: "Бронь отменена",
    successfully_edited: "Успешно изменено",
    success_create_role: "Роль успешно создана",
    success_create_comment: "Комментарий успешно создана",
    success_create_company: "Компания успешно создано",
    success_create_payment: "Счет успешно создана",
    success_update_payment: "Счет успешно редактировано",
    success_update_company: "Компания успешно редактировано",
    success_delete_company: "Компания успешно удалено",
    success_update_role: "Роль успешно редактирована",
    are_you_sure_delete_role:
      "Если удалите роль вы должны назначить новый роль для пользователей текущей роли", // 'text_cancel_contract': 'Вы действительно хотите расторгнуть контракт',
    payment_edit: "Редактировать платеж",
    debtors: {
      payment_info: "Сумма платежа!",
      payment_edit: "Редактировать платеж",
      payment_delete: "Вы действительно хотите удалить платеж?",
      next: "Продолжить",
      are_you_sure:
        "Вы точно хотите подтвердить оплату? Потом невозможно отменить!",
      yes: "Да подтвердить оплату",
      placeholder: "Если есть комментарии к оплату напишите",
    },
  },

  user: {
    first_name: "Имя",
    last_name: "Фамилия",
    second_name: "Очества",
    phone: "Номер телефона",
    password: "Пароль",
    email: "E-mail",
    role: "Роль",
    phone_number: "Номер телефона",
    validation_last_password: "Cтарый пароль неверен!",
    validation_password: "Пароль должен содержать не менее 8 символов!",
    validation_confirm_password:
      "Подтверждение пароля должно совпадать с новым паролем",
  },

  users: {
    title: "Пользователи",
    name: "Имя",
    phone: "Номер телефона",
    roles: "Роль",
    object: "Объект",
    branch: "Филиал",
    login: "Логин",
    placeholder: "ФИО, телефон, email",
  },

  accountants: {
    title: "Бухгалтерия",
    role_title: "Бухгалтерия",
  },

  cashier: {
    title: "Кассиры",
  },

  settings: {
    title: "Настройки",
  },

  branches: {
    title: "Филиалы",
    name: "Филиал",
    branch_name: "Название филиала",
    branch_address: "Адрес филиала",
    branch_phone: "Номер телефона филиала",
    branch_manager: "Менеджер филиала",
    manager_first_name: "Имя менеджера",
    manager_last_name: "Фамилия менеджера",
    manager_second_name: "Отчество менеджера",
    manager_phone_number: "Номер телефона менеджера",
    manager_email: "Почта менеджера",
    manager_avatar: "Аватар менеджера",
    date_contract: "Дата соглашения",
    number_contract: "Номер соглашения",
    date_implementation: "Дата поручения",
    number_implementation: "Номер поручения",
  },

  filter_names: {
    all: "Все",
    booked: "Забронирован",
    sold: "Оформлен",
    in_payment: "На оплате",
    archive: "Закрыт",
  },

  contract_compare: {
    compare_title: "Данные для сравнения",
    course_usd: "Курс доллара при продаже",
    tariff: "Тариф продажи",
    first_price: "Начальная общая цена",
    full_price: "Общая цена при продаже",
    discount: "Общая скидка",
    first_price_m2: `Начальная цена за М<sup>2</sup>`,
    last_price_m2: `Цена за М<sup>2</sup> при продаже`,
    discount_m2: `Скидка за м<sup>2</sup>`,
  },
};
