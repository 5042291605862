var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{ref:"modal-view-reserved-status",attrs:{"id":"modal-view-reserved-client","title":_vm.$t('apartments.list.view_client'),"hide-footer":""}},[_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-cols":"4","label-cols-lg":"2","label":_vm.$t('user.first_name'),"label-for":"first_name"}},[_c('b-form-input',{attrs:{"id":"first_name","disabled":"","value":_vm.getReserveClient.first_name && _vm.getReserveClient.first_name.lotin}})],1),_c('b-form-group',{attrs:{"label-cols":"4","label-cols-lg":"2","label":_vm.$t('user.last_name'),"label-for":"last_name"}},[_c('b-form-input',{attrs:{"id":"last_name","disabled":"","value":_vm.getReserveClient.last_name && _vm.getReserveClient.last_name.lotin}})],1),_c('b-form-group',{attrs:{"label-cols":"4","label-cols-lg":"2","label":_vm.$t('user.phone'),"label-for":"phone"}},[_c('b-form-input',{attrs:{"id":"phone","disabled":"","value":_vm.getReserveClient.phone}})],1),_c('b-form-group',{attrs:{"label-cols":"4","label-cols-lg":"4","label":_vm.$t('apartments.list.period_date'),"label-for":"period_date"}},[_c('b-form-datepicker',{attrs:{"disabled":"","locale":"ru","value":_vm.getReserveClient.booking_date}})],1),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{attrs:{"type":"button","variant":"light"},on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t("close"))+" ")]),(
            (_vm.getApartment.order.status === 'booked' &&
              _vm.getApartment.order.user.id === _vm.getMe.user.id) ||
            (_vm.getMe.role.id === 1 && _vm.getApartment.order.status === 'booked') ||
            (_vm.getApartment.order.status === 'booked' &&
              _vm.getPermission.apartments.root_contract)
          )?_c('b-button',{staticClass:"ml-1",attrs:{"type":"submit","variant":"danger"}},[_c('i',{staticClass:"fas fa-eraser"}),_vm._v(" "+_vm._s(_vm.$t("apartments.list.cancel_reserve"))+" ")]):_vm._e()],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }