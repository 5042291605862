var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-header',{scopedSlots:_vm._u([{key:"header-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("branches.title"))+" ")]},proxy:true},{key:"header-actions",fn:function(){return [(_vm.createPermission)?_c('base-button',{attrs:{"text":_vm.$t('add'),"design":"violet-gradient"},on:{"click":_vm.createBranch},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('BasePlusIcon',{attrs:{"fill":"var(--white)"}})]},proxy:true}],null,false,1855487057)}):_vm._e()]},proxy:true}])}),_c('div',[_c('div',{staticClass:"pt-2"},[_c('b-table',{staticClass:"custom-table",attrs:{"sticky-header":"","borderless":"","responsive":"","items":_vm.branches,"fields":_vm.fields,"show-empty":"","sort-icon-left":"","empty-text":_vm.$t('no_data')},scopedSlots:_vm._u([{key:"empty",fn:function(scope){return [_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_vm._v(" "+_vm._s(scope.emptyText)+" ")])]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-center w-100"},[_c('div',{staticClass:"lds-ellipsis"},[_c('div'),_c('div'),_c('div'),_c('div')])])]},proxy:true},{key:"cell(name)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" ")]}},{key:"cell(manager)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.getFullName(data.item))+" ")]}},{key:"cell(address)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.address)+" ")]}},{key:"cell(phone)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.phone)+" ")]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"float-right"},[(
                _vm.deletePermission || _vm.viewTemplatesPermission || _vm.editPermission
              )?_c('div',{staticClass:"dropdown my-dropdown dropleft"},[_c('button',{staticClass:"dropdown-toggle",attrs:{"type":"button","data-toggle":"dropdown"}},[_c('i',{staticClass:"far fa-ellipsis-h"})]),_c('div',{staticClass:"dropdown-menu"},[(_vm.editPermission)?_c('router-link',{class:'dropdown-item dropdown-item--inside',attrs:{"to":{
                    name: 'edit-branch',
                    params: { id: data.item.id, historyForm: data.item },
                  }}},[_c('i',{staticClass:"fas fa-pen"}),_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")]):_vm._e(),(_vm.viewTemplatesPermission)?_c('router-link',{class:'dropdown-item dropdown-item--inside',attrs:{"to":{
                    name: 'object-deal-template',
                    params: { id: data.item.id },
                  }}},[_c('i',{staticClass:"far fa-file-alt"}),_vm._v(" "+_vm._s(_vm.$t("objects.deal_template.name"))+" ")]):_vm._e(),(_vm.deletePermission)?_c('button',{staticClass:"dropdown-item dropdown-item--inside",on:{"click":function($event){return _vm.deleteBranch(data.item.id)}}},[_c('span',[_c('i',{staticClass:"far fa-trash"})]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")])]):_vm._e()],1)]):_vm._e()])]}}]),model:{value:(_vm.sortDesc),callback:function ($$v) {_vm.sortDesc=$$v},expression:"sortDesc"}})],1)]),_c('b-overlay',{staticStyle:{"z-index":"2222"},attrs:{"show":_vm.loading,"no-wrap":"","opacity":"0.5"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-center w-100"},[_c('div',{staticClass:"lds-ellipsis"},[_c('div'),_c('div'),_c('div'),_c('div')])])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }