var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-header',{scopedSlots:_vm._u([{key:"header-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("roles.title"))+" ")]},proxy:true},{key:"header-actions",fn:function(){return [(_vm.createPermission)?_c('base-button',{staticClass:"ml-4",attrs:{"design":"violet-gradient","text":_vm.$t('add')},on:{"click":function($event){return _vm.$router.push({ name: 'roles-store' })}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('BasePlusIcon',{attrs:{"fill":"var(--white)"}})]},proxy:true}],null,false,1855487057)}):_vm._e()]},proxy:true}])}),_c('div',[_c('b-table',{staticClass:"custom-table",attrs:{"sticky-header":"","borderless":"","responsive":"","items":_vm.getRoles,"fields":_vm.fields,"busy":_vm.getLoading,"show-empty":"","sort-icon-left":"","empty-text":_vm.$t('no_data')},scopedSlots:_vm._u([{key:"empty",fn:function(scope){return [_c('span',{staticClass:"d-flex justify-content-center align-items-center"},[_vm._v(" "+_vm._s(scope.emptyText))])]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-center w-100"},[_c('div',{staticClass:"lds-ellipsis"},[_c('div'),_c('div'),_c('div'),_c('div')])])]},proxy:true},{key:"cell(name)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.getName(data.item.name))+" ")]}},{key:"cell(users_page)",fn:function(data){return undefined}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"float-right"},[_c('div',{staticClass:"d-flex align-items-center"},[(
                data.item.id !== 1 && (_vm.editPermission || _vm.deletePermission)
              )?_c('div',{staticClass:"dropdown my-dropdown dropleft"},[_c('button',{staticClass:"dropdown-toggle",attrs:{"type":"button","data-toggle":"dropdown"}},[_c('i',{staticClass:"far fa-ellipsis-h"})]),(_vm.editPermission || _vm.deletePermission)?_c('div',{staticClass:"dropdown-menu"},[(data.item.id !== 1 && _vm.editPermission)?_c('router-link',{class:'dropdown-item dropdown-item--inside',attrs:{"to":{ name: 'roles-update', params: { id: data.item.id } }}},[_c('i',{staticClass:"fas fa-pen"}),_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")]):_vm._e(),(data.item.id !== 1 && _vm.deletePermission)?_c('a',{staticClass:"dropdown-item dropdown-item--inside",attrs:{"href":"#"},on:{"click":function($event){return _vm.deleteRole(data.item.id)}}},[_c('i',{staticClass:"far fa-trash"}),_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")]):_vm._e()],1):_vm._e()]):_vm._e(),_c('base-button',{staticClass:"d-flex align-items-center ml-4 violet-600",on:{"click":function($event){return _vm.$router.push({
                  name: 'users',
                  query: {
                    role_id: data.item.id,
                  },
                })}}},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$t("roles.users")))]),_c('span',{staticClass:"d-flex align-items-center"},[_c('x-icon',{attrs:{"name":"visibility","size":"18","color":"var(--violet-600)"}})],1)])],1)])]}}]),model:{value:(_vm.sortDesc),callback:function ($$v) {_vm.sortDesc=$$v},expression:"sortDesc"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }