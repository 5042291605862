<script>
export default {
  name: "BaseTimesCircleIcon",
  props: {
    fill: {
      type: String,
      default: "#9CA3AF",
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
};
</script>
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M22 11.9998C22 17.5238 17.523 21.9998 12 21.9998C6.477 21.9998 2 17.5238 2 11.9998C2 6.47782 6.477 1.99982 12 1.99982C17.523 1.99982 22 6.47782 22 11.9998Z"
      :fill="fill"
    />
    <path
      d="M13.2689 12.0002L14.7388 10.5291C15.0891 10.18 15.0891 9.61303 14.7388 9.26273C14.3885 8.91242 13.8215 8.91242 13.4712 9.26273L12.0014 10.7314L10.5315 9.26273C10.1812 8.91242 9.61303 8.91242 9.26273 9.26273C8.91242 9.61303 8.91242 10.18 9.26273 10.5291L10.7338 12.0002L9.26273 13.47C8.91242 13.8203 8.91242 14.3873 9.26273 14.7364C9.43848 14.9121 9.6672 15 9.89713 15C10.1271 15 10.3558 14.9121 10.5315 14.7364L12.0014 13.2677L13.4712 14.7364C13.6457 14.9121 13.8757 15 14.1056 15C14.3343 15 14.5642 14.9121 14.7388 14.7364C15.0891 14.3873 15.0891 13.8203 14.7388 13.47L13.2689 12.0002Z"
      :fill="fill"
    />
  </svg>
</template>
